import React, { useEffect, useState } from 'react';
import { BigNumber, ethers } from 'ethers';
import OctoVX from '../abi/OctoHedzVXABI.json'
import OctoVX2 from '../abi/OctoHedzVX2ABI.json'
import WLaddresses from '../MerkelTree/Addresses.json'
import inkzLogo from '../images/INKZ_TOKEN.gif'
import VX from '../images/DROC3.gif'
import {
    connectWallet,
    getCurrentWalletConnected,
} from "../utils/interact.js";

  
const Mint = ({accounts, setAccounts}) => {
  const[mintAmount, setMintAmount] = useState(1);
  const[saleState, setSaleState] = useState(false);
  const[privateSaleState, setPrivateSaleState] = useState(false);
  const[RegularWhitelist, setRegularWhitelist] = useState(false);
  const[PublicMinted, setPublicMinted] = useState(0);
  const[TotalMinted, setTotalMinted] = useState(0);
  const[TotalMinted2, setTotalMinted2] = useState(0);  
  const [walletAddress, setWallet] = useState("");
  const [status, setStatus] = useState("");
  const[CheckProof, setCheckProof] = useState(false);
  const [Hex, setHex] = useState("");



  const isConnected = Boolean(accounts[0]);
 
  // ADDRESSES
    const octoHedzVXAdrs = "0xa7aA345FfaCdaD6A7d2633C8A10897c3D46b49F7" //Mainnet
    const octoHedzVX2Adrs = "0xf7aB6d94DdDA3Ebe6362fF4ad503a4b61d545639"  //Mainnet
    // const octoHedzVXAdrs = "0xdFF382969bA462E7A1DF6120468f94c1C59a6fC9" //test
    // const octoHedzVX2Adrs = "0xAC50bbC352902E6118071Eb0F9F54e81D63b9853"   //test


const provider = new ethers.providers.Web3Provider(window.ethereum);
const signer = provider.getSigner();

//contracts
const octoHedzVXContract = new ethers.Contract(octoHedzVXAdrs, OctoVX.abi, signer);
const octoHedzVX2Contract = new ethers.Contract(octoHedzVX2Adrs, OctoVX2.abi, signer);

const WL_TOKEN_PRICE = 0.03;
const TOKEN_PRICE = 0.04;
  



const { MerkleTree } = require('merkletreejs');
const keccak256 = require('keccak256');

let whitelistAddresses = WLaddresses.WLaddresses;

const leafNodes = whitelistAddresses.map(addr => keccak256(addr));
const merkleTree = new MerkleTree(leafNodes, keccak256, { sortPairs: true});


const rootHash = merkleTree.getHexRoot();


     // Public mint function
     async function handlePublicMint() {
      if (window.ethereum) {
          try {
              const response = await octoHedzVX2Contract.getOctoHedz(BigNumber.from(mintAmount), {
                  value: ethers.utils.parseEther((TOKEN_PRICE * mintAmount).toString()),
              });
              alert('response: ', response)
              //console.log('response: ', response);
          } catch(err) {

            if (err.code === 4001) {
				alert('Minting cancelled')
			} else {
            //   console.log("error: ", err)
            alert(String(err).substring(0, 26))
        }
          }
      }
  };

  //whitelist mint function 
  async function handleWLMint() {

    if (window.ethereum) {
            try {

                const response = await octoHedzVX2Contract.OctoHedzWL(BigNumber.from(mintAmount), Hex, {
                    value: ethers.utils.parseEther((WL_TOKEN_PRICE * mintAmount).toString()),
                });
                alert('response: ', response)
                //console.log('response: ', response);
            } catch(err)  {
            if (err.code === 4001) {
				alert('Minting cancelled')
			} else {
            //   console.log("error: ", err)
            alert(String(err).substring(0, 26))
        }
          }
        }
    };  
        

  async function handleInfo() {
    if (window.ethereum) {
        try {
            let userAddress = await signer.getAddress();

            let hasSaleStarted = await octoHedzVX2Contract.hasSaleStarted();
            let hasPrivateSaleStarted = await octoHedzVX2Contract.preSaleIsActive();
            let mintedOcto = await octoHedzVXContract.totalSupply();
            let mintedOcto2 = await octoHedzVX2Contract.totalSupply();
            let claimingAddress = keccak256(userAddress);  
            let hexProof = await merkleTree.getHexProof(claimingAddress);
            setSaleState(hasSaleStarted);
            setPrivateSaleState(hasPrivateSaleStarted);
            setTotalMinted(parseInt(mintedOcto));
            setTotalMinted2(parseInt(mintedOcto2));
            setHex(hexProof);
            let checkproof = merkleTree.verify(hexProof, claimingAddress, rootHash)
            setCheckProof(checkproof);
            //console.log("checkproof: ", checkproof);

        } catch(err) {
            console.log("error: ", err)
        }
    }
      
  }

   handleInfo();

  const handleDecrement = () => {
      if (mintAmount <= 1) return;
      setMintAmount(mintAmount - 1);
  };

  const handleIncrement = () => {
      if (mintAmount >= 8) return;
      setMintAmount(mintAmount + 1);
  };


  return (
    <div className='flex justify-center items-center bg-octohedz'>
        <div className='bg-octohedz'>
            
        
            <div className='flex flex-wrap justify-center items-center'>
                <div className='rounded-lg bg-octohedz-l items-center p-6 py-10 m-2 '>
                    <div className='justify-center items-center'>
                    <p className='text-3xl px-2 t-octohedz mint-tittle' id='mint-toggle-text'>OctoHedz VX</p>
                    <div className=''>
                       <img src={VX} alt='INKz gif' />
                    </div>

                    </div>
                    <div>
                        <p className='text-xl italic t-octohedz mx-2'>VX Minted: {TotalMinted + TotalMinted2} </p>

                        <p className='text-xl italic t-octohedz mx-2'></p>
                    </div>
                    <div className='flex justify-center items-center'>
                        
                        <button className='bg-black hover:bg-pink-400 t-octohedz p-1 text-4xl'onClick={handleDecrement}>
                            -
                        </button>
                        <input className='bg-octohedz text-l p-2 t-octohedz' type="number" readOnly value={mintAmount} />
                        <button className='bg-black hover:bg-pink-400 t-octohedz p-1 text-4xl'onClick={handleIncrement}>
                            +
                        </button>
                    </div>
                    <div className=''>
                      <div className='flex flex-col justify-center'>
                      {saleState ? (
                         <button className='bg-green-500 hover:bg-green-300 px-4 py-2 text-xl' onClick={handlePublicMint}>Public Mint 0.04E</button>
                       ) :
                         <button className='bg-grey-700 hover:bg-grey-500 px-4 py-2 text-xl'>Public Mint 0.04E</button>
                      } 

                      {privateSaleState && CheckProof > 0 ? (   
                         <button className=' bg-black px-4 py-2 t-octohedz text-xl' onClick={handleWLMint}>WL Mint 0.03E</button> 
                       ) :
                         <button className='bg-grey-500 hover:bg-grey-500 px-4 py-2 text-xl'>WL Mint 0.03E</button>
                      }  
                      </div>
                    </div>
                    <p className='text-l text-white pt-4'>Max 8 per Allow List {RegularWhitelist}</p>
                </div>
            </div>
            
        
        
        </div>
    </div>
)
};

export default Mint;
